import { RouterProvider, Route, createRoutesFromElements, createBrowserRouter } from 'react-router-dom';

import Home from "./pages/Home.js";
import Wine from "./pages/Wine.js";

import wineList from './images/wineList.json';

const wineLoader = (params) => {
  var returnObj = {};
  wineList.forEach((item, index) => {
    if (item.nazivVina === params.params.nazivVina) {
      returnObj = item;
      return false;
    }
  });
  return returnObj;
};


const CustomRoutes = (


  <>
    <Route path="/" element={<Home />}></Route>
    <Route path="/vino/:nazivVina"
      element={<Wine />}
      loader={wineLoader}
      id="wine">
    </Route>
  </>

)

const routes = createRoutesFromElements(CustomRoutes);

const router = createBrowserRouter(routes);

function App() {


  return (
    <RouterProvider router={router} />
  );
}

export default App;
