import React, { useRef } from 'react'
import AboutUs from './home/Aboutus'
import Products from './home/Products'
import Machines from './home/Machines'
import Contact from './home/Contact'
import Landing from './home/Landing'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

import "../styles/Home.css";
const Home = () => {
  const landingRef = useRef(null);
  const aboutUsRef = useRef(null);
  const productsRef = useRef(null);
  const rentablesRef = useRef(null);
  const contactsRef = useRef(null);




  const navigate = (slide) => {
    switch (slide) {
      case "landing":
        landingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case "aboutus":
        aboutUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case "products":
        productsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case "rentables":
        rentablesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case "contacts":
        contactsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      default:

    }
  }

  return (
    <>
      <main className="relative">
        <Navigation navigate={navigate} />
        <section ref={landingRef}>
          <Landing navigate={navigate} />
        </section>
        <section ref={aboutUsRef}>
          <AboutUs />
        </section>
        <section className="kn-background-divider-1">
        </section>
        <section ref={productsRef}>
          <Products />
        </section>
        <section className="kn-background-divider-2">
        </section>
        <section ref={rentablesRef}>
          <Machines />
        </section>
        <section ref={contactsRef}>
          <Contact />
        </section>
      </main >
      <Footer />
    </>
  )
}

export default Home;
