import React from 'react'
import '../../styles/Landing.css'
import bg from "../../images/bg.png"
import video from "../../images/video_CZczGQ9a.mp4"
const Landing = (props) => {

  return (
    <div className="kn-landing-section">
      <div className="kn-landing-content-block kn-landing-content-block-1">
        <h2>Vinarija OPG Tudor</h2>
        <span className="kn-ladning-diamond"></span>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <button onClick={() => props.navigate("aboutus")}>Read More</button>
      </div>
      <div className="kn-landing-content-block kn-landing-content-block-2">
        <video autoPlay muted loop="true">
          <source src={video}
            type="video/mp4" />
        </video>
      </div>
      <div className="kn-landing-content-block kn-landing-content-block-3">
        <img src={bg} alt="pozadina" />

      </div>
    </div>
  )
}

export default Landing
