import React from 'react'
import { Link } from "react-router-dom";
import '../styles/Article.css'

const Article = (props) => {

  const limitedDescription = (description) => {
    return description.length > 100 ? description.substring(0, 100) + "..." : description;
  }
  return (
    <div className="kn-wines-article">
      <img src={props.image} alt={props.alt}></img>
      <h3>{props.title}</h3>
      <p>{limitedDescription(props.description)}</p>
      <Link to={`/vino/${props.slug}`}>Read more</Link>
    </div>
  )
}

export default Article;
