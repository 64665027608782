import React from 'react'
import '../styles/Footer.css'

const Footer = (props) => {

    return (
        <footer className="kn-footer">
            <div className="kn-credits"><p>© 2023 OPG Tudor</p></div>
        </footer>
    )
}

export default Footer;
