import React from 'react'
import {useLoaderData} from 'react-router-dom';
const Wine = (props) => {

    const vinoProps = useLoaderData();
    console.log(vinoProps);
  return (
    <div>
      {Object.entries(vinoProps).map((item ) => <p>{item[0] + " "+item[1]}</p>)}
    </div>
  )
}

export default Wine
