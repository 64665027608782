import React from 'react'
import ArticleSliderRight from '../../components/ArticleSliderRight';
import wineList from '../../images/wineList.json';
import '../../styles/Machines.css'
const Machines = () => {
    return (
        <div className="kn-products-section-right">

            <div className="kn-products-text-container-right">
                <h2 className="kn-products-title-right">Asortiman naših vina</h2>
                <p className="kn-products-text-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div className="kn-products-button-container-right">
                    <button className="kn-products-read-more-right">Pogledajte sva vina</button>
                </div>
            </div>
            <ArticleSliderRight articles={wineList}></ArticleSliderRight>
        </div>

    )
}

export default Machines;
