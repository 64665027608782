import React from 'react';
import "../../styles/AboutUs.css";

import wineBarrel from "../../images/barrel-oak-wine.png";

const Aboutus = () => {
  return (
    <div className="kn-about-us-section">
      <div className="kn-about-us-section-container kn-about-us-section-half-left">
        <h3>Priča naših vinograda</h3>
        <span className="kn-about-us-separator"></span>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div>
      <div className="kn-about-us-section-container kn-about-us-section-half-right">
        <img className="kn-about-us-section-image" src={wineBarrel} />
      </div>
    </div>
  )
}

export default Aboutus;
