import React, { useState } from 'react';
import Article from './Article';
import '../styles/ArticleSliderRight.css'

import bottleRight from "../images/bottle-products.png";

function ArticleSliderRight(props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePreviousClick = () => {
        const newIndex = currentIndex - 1;
        setCurrentIndex(newIndex < 0 ? props.articles.length - 1 : newIndex);
    };

    const handleNextClick = () => {
        const newIndex = currentIndex + 1;
        setCurrentIndex(newIndex === props.articles.length ? 0 : newIndex);
    };

    const width = (props.articles.length * 400);

    return (
        <div className="kn-article-slider-container-right">
            <div className="kn-outer-article-container-right">
                <div className="kn-articles-slider-right" style={{ right: (width - window.innerWidth / 2) + 'px', width: width + 'px', transform: `translateX(${parseFloat(currentIndex / props.articles.length) * 100}%)` }}>
                    {props.articles.map((item, key) => {
                        return (
                            <Article key={key} image={item.slika} title={item.naslov} description={item.opis} slug={item.nazivVina}></Article>
                        )
                    })}
                </div>
                <div className="kn-article-slider-controls-right">
                    {
                        currentIndex !== 0 &&
                        <button className="kn-article-button-right-prev" onClick={handlePreviousClick}>
                            <i className="fa-solid fa-angle-left"></i>
                        </button>
                    }
                    {
                        currentIndex !== (props.articles.length - 1) &&
                        <button className="kn-article-button-right-next" onClick={handleNextClick}>
                            <i className="fa-solid fa-angle-right"></i>
                        </button>
                    }
                </div>
            </div>
            <div className="kn-article-slider-bottle-right">
                <img src={bottleRight} alt="bottle right" />
            </div>
        </div>
    );
}

export default ArticleSliderRight;