import React from 'react'
import '../styles/Navigation.css'
const Navigation = ({ navigate }) => {


  return (
    <div className="kn-navigation">
      <div>
        <button className="kn-navigation-button" onClick={e => navigate("landing")}>
          <span className="kn-navigation-label">Landing</span>
          <span className="kn-navigation-diamond"></span>
        </button>
        <button className="kn-navigation-button" onClick={e => navigate("aboutus")}>
          <span className="kn-navigation-label">About us</span>
          <span className="kn-navigation-diamond"></span>
        </button>
        <button className="kn-navigation-button" onClick={e => navigate("products")}>
          <span className="kn-navigation-label">Products</span>
          <span className="kn-navigation-diamond"></span>
        </button>
        <button className="kn-navigation-button" onClick={e => navigate("rentables")}>
          <span className="kn-navigation-label">Machines</span>
          <span className="kn-navigation-diamond"></span>
        </button>
        <button className="kn-navigation-button" onClick={e => navigate("contacts")}>
          <span className="kn-navigation-label">Contacts</span>
          <span className="kn-navigation-diamond"></span>
        </button>
      </div>
    </div>
  )
}

export default Navigation
