import React, { useState } from 'react';
import Article from './Article';
import '../styles/ArticleSlider.css'

import bottleLeft from "../images/bottle-products.png";

function ArticleSlider(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePreviousClick = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex < 0 ? props.articles.length - 1 : newIndex);
  };

  const handleNextClick = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex === props.articles.length ? 0 : newIndex);
  };

  return (
    <div className="kn-article-slider-container">
      <div className="kn-article-slider-bottle-left">
        <img src={bottleLeft} />
      </div>
      <div className="kn-outer-article-container">
        <div className="kn-articles-slider" style={{ width: (props.articles.length * 400) + 'px', transform: `translateX(-${parseFloat(currentIndex / props.articles.length) * 100}%)` }}>
          {props.articles.map((item, key) => {
            return (
              <Article key={key} image={item.slika} title={item.naslov} description={item.opis} slug={item.nazivVina}></Article>
            )
          })}
        </div>
        <div className="kn-article-slider-controls">
          {
            currentIndex !== 0 &&
            <button className="kn-article-button-prev" onClick={handlePreviousClick}>
              <i className="fa-solid fa-angle-left"></i>
            </button>
          }
          {
            currentIndex !== (props.articles.length - 1) &&
            <button className="kn-article-button-next" onClick={handleNextClick}>
              <i className="fa-solid fa-angle-right"></i>
            </button>
          }
        </div>
      </div>
    </div>
  );
}

export default ArticleSlider;