import React from 'react'
import ArticleSlider from '../../components/ArticleSlider';
import wineList from '../../images/wineList.json';
import '../../styles/Products.css'
const Products = () => {
  return (
    <div className="kn-products-section">

      <div className="kn-products-text-container">
        <h2 className="kn-products-title">Asortiman naših vina</h2>
        <p className="kn-products-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <div className="kn-products-button-container">
          <button className="kn-products-read-more">Pogledajte sva vina</button>
        </div>
      </div>
      <ArticleSlider articles={wineList}></ArticleSlider>
    </div>

  )
}

export default Products
