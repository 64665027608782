import React from 'react';

import "../../styles/Contact.css";

const Contact = () => {
  return (
    <div className="kn-contact-slider-container">
      <div className="kn-contact-inner-container">
        <div className="kn-contact-container-half">
          <h3 className="kn-contact-form-title">Contact us</h3>
          <span class="kn-contact-diamond"></span>
          <form className="kn-contact-form" onSubmit={(event) => { event.preventDefault(); }}>
            <input type="text" className="kn-contact-form-input" placeholder="Subject" />
            <input type="email" className="kn-contact-form-input" placeholder="Email" />
            <textarea className="kn-contact-form-textarea" placeholder="Write your message"></textarea>
            <button type="submit" className="kn-contact-form-submit">Send</button>
          </form>
        </div>
        <div className="kn-contact-container-half">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d414276.69045861467!2d16.010526403717794!3d45.40206588541129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4766ee1b2d3e6da1%3A0x11690f14b6997a99!2sPokuplje!5e0!3m2!1shr!2shr!4v1677495607480!5m2!1shr!2shr" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact;
